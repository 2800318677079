import { isPlatformBrowser } from '@angular/common';
import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  PLATFORM_ID,
  effect,
  input,
} from '@angular/core';
import { WindowService } from 'src/app/shared/services/window.service';

@Directive({
  selector: '[appScrollToElement]',
  standalone: true,
})
export class ScrollToElementDirective {
  elementRef = input.required<ElementRef | HTMLElement>();
  triggerScroll = input(false);
  constructor(
    private windowService: WindowService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    effect(() => {
      if (this.triggerScroll() && isPlatformBrowser(platformId)) {
        this.onClick();
      }
    });
  }
  @HostListener('click')
  onClick = () => {
    const headerOffset = 100;
    let elementPosition: number;

    if (this.elementRef() instanceof ElementRef) {
      const ref = this.elementRef() as ElementRef;
      elementPosition = ref.nativeElement.getBoundingClientRect().top;
    } else {
      const ref = this.elementRef() as HTMLElement;
      elementPosition = ref.getBoundingClientRect().top;
    }
    const offsetPosition = elementPosition + window.scrollY - headerOffset;
    // here we implement setTimeout because browser needs time to calculate the layout and position of elements.
    setTimeout(() =>
      this.windowService.windowRef.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      }),
    );
  };
}
